import React, { useEffect, useState } from 'react';
import './App.css';
// Initialize provider
import Fortmatic from 'fortmatic';
import Web3 from 'web3';
import Button from '@mui/material/Button';
import axios from 'axios';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { ToastContainer } from 'react-toastify';
import { toastError, toastSuccess } from './lib/Toast';
import 'react-toastify/dist/ReactToastify.css';
import ResponsiveAppBar from './components/ResponsiveAppBar';

import {
  Grid,
  Card,
  CardContent,
  Typography,
} from '@mui/material/'



const App = () => {



  const fm = new Fortmatic(process.env.REACT_APP_FORTMATIC_KEY);
  let web3 = new Web3(fm.getProvider());
  const [address, setAddress] = useState(false);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [certifNum, setCertifNum] = useState(0);
  const [contractAddr] = useState(process.env.REACT_APP_CONTRACT_ADDRESS);



  useEffect(() => {
    checkLogin();
  }, [])



  useEffect(() => {
    if (address && nfts.length === 0) {
      setLoading(true);
      loadNFTs();
    }
  }, [address])


  async function login() {
    fm.user.login().then(() => {
      web3.eth.getAccounts(async (error, accounts) => {
        setLoading(true);
        let user = await fm.user.getUser();
        await whitelist(user.email, accounts[0]);
      });
    });
  }


  async function sendAddress(email, address) {
    try {
      const response = await fetch(process.env["NODE_ENV"] === "development" ? "/user" : "https://lrh.tomorrowtheory.com/api/user", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: encryptId(email),
          address: encryptId(address),
        }),
      });

      const content = await response.json();
      console.log(content);

      if (response.ok) {
        console.log("OK!");
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function whitelist(email, address) {
    const encryptedEmail = encryptId(email)
    try {
      const response = await fetch(process.env["NODE_ENV"] === "development" ? "/user/email?email=" + encryptedEmail : "https://lrh.tomorrowtheory.com/api/user/email?email=" + encryptedEmail, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const content = await response;
      console.log(content);

      if (response.ok) {
        setAddress(address); // ['0x...']
        sendAddress(email, address);
      } else {
        loadNFTs(address, email);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const encryptId = (str) => {
    let CryptoJS = require("crypto-js");
    let key = process.env.REACT_APP_SALT_KEY;
    const ciphertext = CryptoJS.AES.encrypt(str, key);
    return encodeURIComponent(ciphertext.toString());
  }


  async function logout() {
    setAddress('');
    setCertifNum(0);
    setNfts([]);
    await fm.user.logout();
    toastSuccess('You have logged out successfully');
    setLoading(false);
  }


  async function logoutUnauthorized() {
    await fm.user.logout();
    setAddress('');
    setCertifNum(0);
    setNfts([]);
    toastError('You are not authorized to access the app.');
    setLoading(false);
  }


  async function checkLogin() {
    setLoading(true);
    if (await fm.user.isLoggedIn()) {
      await web3.eth.getAccounts((error, accounts) => {
        setAddress(accounts[0]); // ['0x...']
      });
    } else {
      setLoading(false);
    }
  }


  async function settings() {
    await fm.user.settings();
  }


  async function loadNFTs(whitelist = null, email = null) {
    const apiKey = process.env.REACT_APP_ALCHEMY_KEY;
    const baseURL = `https://polygon-mainnet.g.alchemyapi.io/v2/${apiKey}/getNFTs`;
    // replace with the wallet address you want to query for NFTs
    var config = {
      method: 'get',
      //url: `${baseURL}?owner=${whitelist ? whitelist : address}&contractAddresses[]=${contractAddr}` to change
      url: `${baseURL}?owner=${address}&contractAddresses[]=${contractAddr}`
    };

    axios(config)
      .then(response => {
        if (whitelist && response.data.ownedNfts.length === 0) {
          logoutUnauthorized();
        } else if (email) {
          setNfts(response.data.ownedNfts);
          setAddress(whitelist); // ['0x...']
          sendAddress(email, whitelist);
          setCertifNum(response.data.ownedNfts.length);
          setLoading(false);
        } else if (!whitelist && response.data.ownedNfts.length > 0) {
          setNfts(response.data.ownedNfts);
          setCertifNum(response.data.ownedNfts.length);
          setLoading(false);
        } else {
          setLoading(false);
        }

      })
      .catch(error => {
        console.log(error);
        logoutUnauthorized();
      });
  }

  function getTimestamp(myDate) {
    var dateParts = myDate.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    let newDate = new Date()

    return newDate < dateObject;
  }

  return (
    <div id="page-container" style={{ fontFamily: 'LVMH' }}>
      <ResponsiveAppBar
        address={address}
        loading={loading}
        login={login}
        logout={logout}
        settings={settings}
      />
      <ToastContainer />
      <div id="content-wrap" style={{ flexGrow: '1', paddingLeft: '5%', paddingRight: '5%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px' }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <h1 style={{ paddingBottom: '20px', overflowWrap: 'break-word', color: '#C88238', lineHeight: '1em' }}>
              <b style={{ paddingBottom: '20px', overflowWrap: 'break-word', color: '#151F6D', lineHeight: '1em' }}>
                <i>Hi</i> LVMH
              </b>
              <br />
              NFT Certification Platform
            </h1>
          </Grid>
          {loading &&
            <Grid item xs={12} sm={12} md={12}>
              <Stack sx={{ width: '100%', color: '#C88238', marginTop: '100px' }} spacing={2}>
                <LinearProgress color="inherit" />
              </Stack>
            </Grid>
          }
          {!address && !loading &&
            <Grid item xs={12} sm={12} md={12}>
              <Button onClick={login} variant="contained" style={{ backgroundColor: '#7379A7', marginBottom: '20px', marginTop: '50px', overflowWrap: 'break-word', fontFamily: 'LVMH' }}>
                Click Here to login
              </Button>
            </Grid>}
          {address && !loading &&
            <Grid item xs={12} sm={12} md={12}>
              <p style={{ paddingBottom: '20px', overflowWrap: 'break-word', lineHeight: '2em', color: '#151F6D' }}>
                <b>{certifNum === 0 ? 'Thank you for registering, your NFT certification will be issued shortly!' : 'You own ' + certifNum + ' certificates in your wallet:'}</b>
              </p>
            </Grid>}
          {address && !loading &&
            nfts.map(elem => (
              <Grid item xs={12} sm={12} md={6} key={nfts.indexOf(elem)}>
                <Card sx={{ minHeight: '500px', maxWidth: '600px' }} style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                  <CardMedia
                    component="img"
                    alt={elem.metadata.attributes.institution}
                    height="100%"
                    image={elem.metadata.image}
                    style={{ width: '60%', display: 'inline' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{ color: '#151F6D' }}>
                       <b>{getTimestamp(elem.metadata.attributes[6].value) ? elem.metadata.name : '[Expired] ' + elem.metadata.name}</b>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '25px', color: '#7379A7' }} >
                      {elem.metadata.description}
                    </Typography>
                    <table id='table1' style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '25px' }}>
                      <tbody>
                        {elem.metadata.attributes.map(attr => (
                          <tr key={attr['value']}>
                            <td style={{ textAlign: 'left', color: '#151F6D', paddingBottom: '10px', textTransform: 'capitalize' }}><b>{attr['trait_type'].replace("_", " ")}</b></td>
                            <td style={{ textAlign: 'right', color: '#7379A7', paddingBottom: '10px' }}>{attr['value']}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardContent>
                  <CardActions>
                    <table style={{ width: '100%', textTransform: 'capitalize', marginLeft: 'auto', marginRight: 'auto', paddingTop: '25px' }}>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'left', color: '#C88238' }}><b><a style={{ textDecoration: 'none', color: '#C88238' }} target='_blank' rel="noopener noreferrer" href={'https://opensea.io/assets/matic/' + contractAddr + '/' + parseInt(elem.id.tokenId, 16)}>View on Opensea</a></b></td>
                          <td style={{ textAlign: 'right', color: '#C88238' }}><b><a style={{ textDecoration: 'none', color: '#C88238' }} target='_blank' rel="noopener noreferrer" href={'https://polygonscan.com/token/' + contractAddr + '?a=' + parseInt(elem.id.tokenId, 16)}>View on the Blockchain</a></b></td>
                        </tr>
                      </tbody>
                    </table>
                  </CardActions>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </div>
    </div>
  );
}

export default App;
