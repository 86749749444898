import * as React from 'react';
import './ResponsiveAppBar.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/img/logo.png';
import logo2 from '../assets/img/logo2.png';
import { toastSuccess } from '../lib/Toast';


const ResponsiveAppBar = (props) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const logoutAndClose = () => {
    handleCloseUserMenu();
    props.logout();
  }


  async function addressCopy() {
    navigator.clipboard.writeText(props.address);
    toastSuccess('Address copied!');
  }


  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img alt='LVMH-Logo1' src={logo} height='100px' />
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 2,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img alt='LVMH-Logo2' src={logo2} height='100px' />
          </Typography>
          <Box sx={{ flexGrow: 1 }} style={{ textAlign: 'center' }}>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {props.address && !props.loading &&
              <Tooltip title="Open settings">
                <Button id='address' onClick={handleOpenUserMenu} style={{ fontSize: '15px', color: 'white', backgroundColor: '#7379A7', fontFamily: 'LVMH', marginRight: '10px' }}>
                  Wallet: {props.address && props.address.replace(props.address.substring(5, 37), "...")}
                </Button>
              </Tooltip>}
            {props.address && !props.loading &&
              <Tooltip title="Logout">
                <Button id='logout' onClick={logoutAndClose} style={{ fontSize: '15px', color: 'white', backgroundColor: '#bb1d38', fontFamily: 'LVMH' }}>
                  Logout
                </Button>
              </Tooltip>}
            {!props.address && !props.loading &&
              <Tooltip title="Open settings">
                <Button onClick={props.login} style={{ fontSize: '15px', color: 'white', backgroundColor: '#7379A7' }}>
                  Login
                </Button>
              </Tooltip>}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={addressCopy}>
                <Typography style={{ fontFamily: 'LVMH' }}>Copy wallet address</Typography>
              </MenuItem>
              <MenuItem onClick={props.settings}>
                <Typography style={{ fontFamily: 'LVMH' }}>Settings</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
